.login {
    height: 100vh;
    /* border: 2px solid red; */
}

.login__container {
    /* height: 100%;     */
    /* border: 1px solid gray !important; */
    min-height: 500px;
    overflow: auto;
    z-index: 1;
    border-radius: 10px;
}

.login_page_logo{
    padding-top: 20px;
    padding-bottom: 40px;
   

}
.login__image {
    height: 100%;
    width: 400px;
}
.login__image img {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
#login .form__section {
    width: 535px;
    position: relative;
    background: white;
    color: black;
    padding: 40px 80px;

}
#login .input__textField{
    height: 58px;
}
.form {
    height: 100%;
    position: relative;
}
.back__arrow {
    position: absolute;
    cursor: pointer;
    top: 40px;
    left: 20px;
}
.register__btn {
    position: absolute;
    bottom: 40px;
    right: 50px;
}
.input__textField {
    width: 300px;
    height: 45px;
    border-radius: 12px;
    border: none;
    outline: none;
    border: 2px solid #e4e4e4;
    padding: 10px;
}
.login_btn {
    width: 100%;
    height: 58px;
    border-radius: 10px;
    background-color: #2D3748;
}


#login .form h4 {
    color: #2D3748;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
}
#login .form p {
    color: #2D3748;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.register__btn {
    width: 100px;
    height: 50px;
}



.remember__forgetPass {
    width: 300px;
    padding-left: 10px;
}
.MuiIconButton-label input {
    display: none !important;
}
.remember__forgetPass a {
    color: #000;
    /* font-weight: bold; */
    cursor: pointer;
}
.remember__forgetPass a:hover {
    text-decoration: none;
    cursor: pointer;
}
.other_links a {
    text-decoration: underline;
}
/* 
.MuiSvgIcon-root path {
    display: none;
} */
.inputField{
    height: 45px;
    width: 300px;
    border-radius: 12px;
    border: none;
    outline: none;
    border: 2px solid #e4e4e4;
    padding: 10px;
}


.passwordChange_submit_btn{
    height: 41px;
    width: 100%;
    border-radius: 10px;
    background-color: #2D3748;
}

@media only screen and (max-width: 855px) {
    .login__image {
        display: none;
    }
}



/* ----------update--------------- */

.landing_image{
    max-width: 100vw;
    max-height: 100vh;
  }
  .landing_image .landing_image_src {
    max-height: 100vh;
    height: 100%;
    width: 100%;
    /* position: absolute; */
  }
  .landing_image .landing-image-content{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
  .landing-image-content .right {
    position: absolute;
    right: 0;
    top: 0;
  }
  .landing_page_home_text h4{
    font-size: 2.4vw !important;
  }
  .login_input_box_section{
        background-color: white;
        box-shadow: 0px 2px 25px 8px #dbdbdb;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 17px !important;
        top: 7rem;
        right: 10rem;
        height: 70vh;
        max-width: 20rem;
        overflow: hidden;
  }

  @media only screen and (max-width: 767px) {
    .login_input_box_section {
        margin: 0 auto;
        left: 0;
        right: 0;
    }

    .landing_image .landing_image_src{
        display: none;
    }
    
    .landing_image {
        background-color: #f8c35a;
    }
}